<template>
  <div class="polaPrinciple">
    <div class="container">
      <div class="left">
        <p class="title">偏光镜是根据光线的偏振原理，如同百叶窗，用来滤除光束中的杂乱光线，选择性地让光线有序进入我们地眼睛。让我们在强光下，视觉更舒适，更清晰，更真实</p>
      </div>
      <div class="right">
        <video style="width: 80%" controls autoplay="autoplay">
          <source src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/video/polaPrinciple.mp4" type="video/mp4">
          <source src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/video/polaPrinciple.mp4" type="video/ogg">
          您的浏览器不支持Video标签。
        </video>
      </div>
    </div>
    <div>
    </div>
  </div>
</template>

<script>
export default {
  name: "polaPrinciple",
  data() {
    return {
    }
  }
}
</script>

<style scoped lang="scss">
.polaPrinciple {
  width: 100%;
  height: 100%;
}
.container {
  display: flex;
  align-items: center;
}
.left {
  flex: 1;
  width: 100%;
  padding-left: 10px;
}
.right {
  flex: 1;
}
.left p {
  text-align: left;
}
.title {
  margin: 10px 0;
  width: 100%;
  font-size: 18px;
}
.typeItem {
  margin-top: 10px;
  overflow: hidden;
}
.typeItem .prefix {
  float: left;
  font-size: 18px;
}
.typeItem .suffix {
  float: left;
}
.typeItem .suffix p {
  font-size: 16px;
}
.radioGroup {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.radioGroup label {
  width: 100px;
  display: flex;
  align-items: center;
}
.radioGroup input {
  margin-right: 5px;
}
.scene img {
  width: 16px;
  height: 16px;
}
.btnGroup {
  display: flex;
  justify-content: center;
}
.btnGroup button {
  margin: 50px;
  padding: 10px 0;
  width: 200px;
  border: 1px solid #333;
  color: #333;
  background: #fff;
}

</style>